import React, { Component } from 'react';
// import PropTypes from 'prop-types';

class Left extends Component {

    constructor(props){
        super(props);
        this.state={
            nav_class:"physician-information",           
        }
    }

   

    render() {

        let activeMenu = this.props.activeMenu == undefined ? 'physician-information' : this.props.activeMenu;
        return (
        <div className="left-section"> 
            <ul className="left-nav">
            <li  className={ activeMenu == 'physician-information' ? " active" : ''}><a href="../physician-information">Physician Information</a></li>
            <li  className={ activeMenu == 'patient-activity' ? "active" : ''}><a  href="../patient-activity">Patient Activity</a></li>
            <li className={ activeMenu == 'ACT-banking-storage-plans' ? "active" : ''}><a href="../ACT-banking-storage-plans">ACT Banking and Storage Plans</a></li>
            <li className={ activeMenu == 'initial-processing' ? "active" : ''}><a href="../initial-processing">New Sample Form </a></li>
            <li className={ activeMenu == 'client-consent' ? "active" : ''} ><a href="../client-consent">New Client Consent Form</a></li>
            <li className={ activeMenu == 'infectious-disease' ? "active" : ''}><a href="../infectious-disease">New Infectious Disease Form</a></li>
            <li className={ activeMenu == 'stem-cell-request' ? "active" : ''}><a href="../stem-cell-request">New Stem Cell Request Form</a></li>
           {/* <li><a href>Order Patient Cells</a></li> */}
            <li className={ activeMenu == 'payment-information' ? "active" : ''}><a href="../payment-information">Update Payment Information</a></li>
            <li className={ activeMenu == 'store' ? "active" : ''}><a href="../Store">ACT Store</a></li>
           
            </ul>
        </div>
        );
    }
}

// Left.propTypes = {

// };

export default Left;